<template>
    <div class="tree-com">
        <Optional
            :allCheck="allCheck"
            :multiple="multiple"
            :checkUserOnly="checkUserOnly"
            :dropDownSearchType="dropDownSearchType"
            :treeShowList1="treeShowList1"
            :breadcrumbsList1="breadcrumbsList1"
            :optionalLoadTitle1="optionalLoadTitle1"
            :searchDropdownData="searchDropdownData"
            :searchOptionalLoadTitle="searchOptionalLoadTitle"
            @selectFocus="selectFocus"
            @selectInput="selectInput"
            @handlerAllTreeNodeClick1="handlerAllTreeNodeClick1"
            @handlerTreeNodeClick="handlerTreeNodeClick"
            @handlerTreeNodeClick1="handlerTreeNodeClick1"
            @treeNodeNextLevelClick1="treeNodeNextLevelClick1"
            @changeTreeListLevel1="changeTreeListLevel1"
        />
        <Selected :selectTreeNodeData="selectTreeNodeData" @handlerDelTreeNode="handlerDelTreeNode"/>
    </div>
</template>
<script>
import Optional from "../components/Optional/index.vue";
import Selected from "../components/Selected/index.vue";
export default {
    name: "TreeCom",
    components: {
        Optional,
        Selected,
    },
    props: {
        allCheck: Boolean,
        multiple: Boolean,
        dropDownSearchType: Boolean,
        treeShowList1: Array,
        selectTreeNodeData: Array,
        breadcrumbsList1: Array,
        optionalLoadTitle1: String,
        searchDropdownData: Array,
        searchOptionalLoadTitle: String,
        checkUserOnly: {
            type: Boolean,
            default: () => {
                return false;
            },
        },
    },
    methods: {
        selectFocus () {
            this.$emit('selectFocus')
        },
        selectInput (inputVal) {
            this.$emit('selectInput', inputVal)
        },
        handlerAllTreeNodeClick1 (data) {
            this.$emit("handlerAllTreeNodeClick1", data)
        },
        handlerTreeNodeClick(data) {
            this.$emit("handlerTreeNodeClick", data);
        },
        handlerTreeNodeClick1(data) {
            this.$emit("handlerTreeNodeClick1", data);
        },
        treeNodeNextLevelClick1 (data) {
            this.$emit("treeNodeNextLevelClick1", data);
        },
        handlerDelTreeNode (data) {
            this.$emit("handlerDelTreeNode", data);
        },
        changeTreeListLevel1 (data) {
            this.$emit("changeTreeListLevel1", data)
        }
    },
};
</script>
<style lang="scss" scoped>
.tree-com {
    display: flex;
    justify-content: center;
}
</style>
