<template>
    <div class="tree-shape" @click.stop="closeSearchPullDown">
        <DialogTitle
            :title="title"
            @closeDialog="closeDialog"
        />
        <tree-com
            :allCheck="allCheck"
            :multiple="multiple"
            :checkUserOnly="checkUserOnly"
            :dropDownSearchType="dropDownSearchType"
            :selectTreeNodeData="selectTreeNodeData"
            :treeShowList1="treeShowList1"
            :breadcrumbsList1="breadcrumbsList1"
            :optionalLoadTitle1="optionalLoadTitle1"
            :searchDropdownData="searchDropdownData"
            @selectFocus="selectFocus"
            @selectInput="selectInput"
            @handlerAllTreeNodeClick1="handlerAllTreeNodeClick1"
            @handlerTreeNodeClick="handlerTreeNodeClick"
            @handlerTreeNodeClick1="handlerTreeNodeClick1"
            @treeNodeNextLevelClick1="treeNodeNextLevelClick1"
            @handlerDelTreeNode="handlerDelTreeNode"
            @changeTreeListLevel1="changeTreeListLevel1"
        ></tree-com>
        <Submit
            :initLoading="initLoading"
            @determine="determine"
            @cancel="cancel"
        />
    </div>
</template>
<script>
import DialogTitle from "../components/Title/DialogTitle.vue";
import TreeCom from "./TreeCom.vue";
import Submit from "../components/Submit/index.vue";

export default {
    name: "TreeShapeView",
    components: {
        DialogTitle,
        TreeCom,
        Submit,
    },
    props: {
        allCheck: Boolean,
        multiple: Boolean,
        treeShowList1: Array,
        selectTreeNodeData: Array,
        breadcrumbsList1: Array,
        optionalLoadTitle1: String,
        dropDownSearchType: Boolean,
        searchDropdownData: Array,
        title: String,
        checkUserOnly: {
            type: Boolean,
            default: () => {
                return false;
            },
        },
        initLoading: {
            type: Boolean,
            default: () => {
                return false;
            },
        }
    },
    methods: {
        selectFocus () {
            this.$emit('selectFocus')
        },
        selectInput (inputVal) {
            this.$emit('selectInput', inputVal)
        },
        handlerAllTreeNodeClick1 (data) {
            this.$emit("handlerAllTreeNodeClick1", data)
        },
        handlerTreeNodeClick(data) {
            this.$emit("handlerTreeNodeClick", data);
        },
        handlerTreeNodeClick1(data) {
            this.$emit("handlerTreeNodeClick1", data);
        },
        treeNodeNextLevelClick1 (data) {
            this.$emit("treeNodeNextLevelClick1", data);
        },
        handlerDelTreeNode (data) {
            this.$emit("handlerDelTreeNode", data);
        },
        changeTreeListLevel1 (data) {
            this.$emit('changeTreeListLevel1', data)
        },
        closeSearchPullDown () {
            this.$emit('closeSearchPullDown')
        },
        determine() {
            this.$emit('determine')
        },
        cancel() {
            this.closeDialog()
        },
        closeDialog() {
            this.$emit('closeDialog')
        },
    },
};
</script>
<style lang="scss" scoped>
.tree-shape {
    position: relative;
    margin: calc((100vh - 666px) / 2) auto;
    width: 700px;
    height: 666px;
    border: 1px solid #ddd;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
}
</style>
